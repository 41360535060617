<template>
    <section id="Margins">
        <BookSelector :books="managedBooks" :selectedName="profile.books[$route.params.book_id].name" />
        <div class="dial">
            <input type="text" class="knob" :value="margins.ratio * 100">
            <div class="margin">
                <h2>{{ this.margins.margin >= 0 ? `$${ this.margins.margin }` : `-$${ Math.abs(this.margins.margin) }` }}</h2>
                <h4>CAD</h4>
            </div>
        </div>
        <div class="card">
            <h3>History</h3>
            <div>
                <div class="row">
                    <h4>Tickets Placed</h4>
                    <h4 class="count">{{ this.margins.ticketsPlaced }}</h4>
                </div>
                <div class="row">
                    <h4>Tickets Paid Out</h4>
                    <h4 class="count">{{ this.margins.ticketsPaid }}</h4>
                </div>
                <div class="row">
                    <h4>Biggest Payout</h4>
                    <h4 class="count">${{ this.margins.biggestPayout }}</h4>
                </div>
                <div class="row">
                    <h4>Parlays Placed</h4>
                    <h4 class="count">{{ this.margins.parlaysPlaced }}</h4>
                </div>
                <div class="row">
                    <h4>Parlays Paid Out</h4>
                    <h4 class="count">{{ this.margins.parlaysPaid }}</h4>
                </div>
            </div>
        </div>
        <Options :options="options" :selected="3" />
    </section>
</template>

<script>

    // Components.
    import BookSelector from '@/components/BookSelector.vue';
    import Options from '@/components/Options.vue';

    // Firebase.
    import { getBet, getWager } from '@/firebase/wagers.js';
    import { watchBook } from '@/firebase/books.js';

    // Misc.
    import { getTicketStatus, adjustedOdds } from '@/utils';
    import { MANAGE_OPTIONS } from '@/constants.js';

    export default {
        name: 'Margins',
        components: {
            BookSelector,
            Options,
        },
        data() {
            return {
                books: {},
                bets: {},
                wagers: {},
            }
        },
        computed: {
            managedBooks() {
                return Object.entries(this.profile.books).filter(book => book[1].membership === 'bookie' || book[1].membership === 'admin');
            },
            options() {
                return MANAGE_OPTIONS.map(option => {
                    return { ...option, params: { book_id: this.$route.params.book_id } };
                });
            },
            margins() {

                let margin        = 0;
                let atStake       = 0;
                let ticketsPlaced = 0;
                let ticketsPaid   = 0;
                let biggestPayout = 0;
                let parlaysPlaced = 0;
                let parlaysPaid   = 0;

                for (const ticket of Object.values(this.bets)) {
                    if (!ticket.void) {

                        const ticketStatus = getTicketStatus(ticket, this.wagers);

                        if (ticketStatus === 'loss') {

                            margin += ticket.options.risk;
                            atStake += ticket.options.risk;
                            ticketsPlaced++;

                            if (ticket.options.bets.length > 1) parlaysPlaced++;

                        } else if (ticketStatus === 'win') {

                            const payout = ((ticket.options.risk * this.adjustedOdds(ticket)) - ticket.options.risk);

                            margin -= payout;
                            atStake += payout;
                            ticketsPlaced++;
                            ticketsPaid++;

                            if (ticket.options.bets.length > 1) {
                                parlaysPlaced++;
                                parlaysPaid++;
                            }

                            if (payout > biggestPayout) biggestPayout = payout;

                        }

                    }
                }

                return {
                    margin: margin.toFixed(2),
                    ratio: ((atStake / 2) + (margin / 2)) / atStake,
                    ticketsPlaced,
                    ticketsPaid,
                    biggestPayout: biggestPayout.toFixed(2),
                    parlaysPlaced,
                    parlaysPaid
                };

            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', true);
            $('.knob').knob({
                min: 0,
                max: 100,
                angleArc: 270,
                angleOffset: -135,
                thickness: 0.1,
                readOnly: true,
                lineCap: 'round',
                bgColor: '#E1E1E1',
                fgColor: this.margins.margin >= 0 ? '#D2AD32' : '#B83939',
                width: window.innerWidth - 40,
                height: window.innerWidth - 40,
            });
        },
        created() {
            this.$store.dispatch('updateLoading', 1);
            watchBook(this.$route.params.book_id).on('value', this.listen);
        },
        watch: {
            $route(to, from) {
                this.$store.dispatch('updateLoading', 1);
                watchBook(from.params.book_id).off('value', this.listen);
                watchBook(to.params.book_id).on('value', this.listen);
            }
        },
        beforeDestroy() {
            watchBook(this.$route.params.book_id).off('value', this.listen);
        },
        methods: {
            listen(snapshot) {

                const val = snapshot.val();
                this.wagers = {};
                this.bets = {};
                this.books[this.$route.params.book_id] = val;

                if (val.wagers) {
                    for (let wager of Object.keys(val.wagers)) {

                        this.$store.dispatch('updateLoading', 1);

                        getWager(wager).then(snapshot => {
                            this.wagers = { ...this.wagers, [snapshot.key]: snapshot.val() };
                            this.$store.dispatch('updateLoading', -1);
                        });

                    }
                }

                if (val.bets) {
                    for (let bet of Object.keys(val.bets)) {

                        this.$store.dispatch('updateLoading', 1);

                        getBet(bet).then(snapshot => {

                            this.bets = { ...this.bets, [snapshot.key]: snapshot.val() };

                            $('.knob').val(this.margins.ratio * 100).trigger('change').trigger('configure', {
                                fgColor: this.margins.margin >= 0 ? '#D2AD32' : '#B83939',
                            });

                            this.$store.dispatch('updateLoading', -1);

                        });

                    }
                }

                this.$store.dispatch('updateLoading', -1);

            },
            adjustedOdds(ticket) {
                return adjustedOdds(ticket, this.wagers);
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    #Margins {
        padding: size(Large) 0 50px + size(Medium);
    }

    .dial {
        position: relative;
        padding: size(Medium);
    }

    input {
        display: none;
    }

    .margin {

        text-align: center;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);

    }

    .margin h2 {
        font-size: 15vw;
        font-weight: 700;
        text-shadow: 0 5px 10px color(Black, 0.15);
    }

    .margin h4 {

        font-size: 7.5vw;
        font-weight: 700;

        margin-top: size(Micro);

    }

    .card {
        padding: size(Medium);
        margin-top: -10vw;
    }

    .card h3 {
        padding-bottom: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .card .row {

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: size(Medium);

    }

    .count {
        color: color(OldGold);
    }

</style>
